@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url("/font/roboto/Roboto-BoldItalic.eot");
  src: url("/font/roboto/Roboto-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-BoldItalic.woff") format("woff"), url("/font/roboto/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-BlackItalic';
  src: url("/font/roboto/Roboto-BlackItalic.eot");
  src: url("/font/roboto/Roboto-BlackItalic.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-BlackItalic.woff") format("woff"), url("/font/roboto/Roboto-BlackItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: 'Roboto-Light';
  src: url("/font/roboto/Roboto-Light.eot");
  src: url("/font/roboto/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-Light.woff") format("woff"), url("/font/roboto/Roboto-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Thin';
  src: url("/font/roboto/Roboto-Thin.eot");
  src: url("/font/roboto/Roboto-Thin.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-Thin.woff") format("woff"), url("/font/roboto/Roboto-Thin.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-MediumItalic';
  src: url("/font/roboto/Roboto-MediumItalic.eot");
  src: url("/font/roboto/Roboto-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-MediumItalic.woff") format("woff"), url("/font/roboto/Roboto-MediumItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Bold';
  src: url("/font/roboto/Roboto-Bold.eot");
  src: url("/font/roboto/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-Bold.woff") format("woff"), url("/font/roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-LightItalic';
  src: url("/font/roboto/Roboto-LightItalic.eot");
  src: url("/font/roboto/Roboto-LightItalic.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-LightItalic.woff") format("woff"), url("/font/roboto/Roboto-LightItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Medium';
  src: url("/font/roboto/Roboto-Medium.eot");
  src: url("/font/roboto/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-Medium.woff") format("woff"), url("/font/roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Regular';
  src: url("/font/roboto/Roboto-Regular.eot");
  src: url("/font/roboto/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-Regular.woff") format("woff"), url("/font/roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-ThinItalic';
  src: url("/font/roboto/Roboto-ThinItalic.eot");
  src: url("/font/roboto/Roboto-ThinItalic.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-ThinItalic.woff") format("woff"), url("/font/roboto/Roboto-ThinItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Black';
  src: url("/font/roboto/Roboto-Black.eot");
  src: url("/font/roboto/Roboto-Black.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-Black.woff") format("woff"), url("/font/roboto/Roboto-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Italic';
  src: url("/font/roboto/Roboto-Italic.eot");
  src: url("/font/roboto/Roboto-Italic.eot?#iefix") format("embedded-opentype"), url("/font/roboto/Roboto-Italic.woff") format("woff"), url("/font/roboto/Roboto-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-Light';
  src: url("/font/poppins/Poppins-Light.eot");
  src: url("/font/poppins/Poppins-Light.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-Light.woff") format("woff"), url("/font/poppins/Poppins-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url("/font/poppins/Poppins-SemiBold.eot");
  src: url("/font/poppins/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-SemiBold.woff") format("woff"), url("/font/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-ExtraLight';
  src: url("/font/poppins/Poppins-ExtraLight.eot");
  src: url("/font/poppins/Poppins-ExtraLight.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-ExtraLight.woff") format("woff"), url("/font/poppins/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-Italic';
  src: url("/font/poppins/Poppins-Italic.eot");
  src: url("/font/poppins/Poppins-Italic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-Italic.woff") format("woff"), url("/font/poppins/Poppins-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-Bold';
  src: url("/font/poppins/Poppins-Bold.eot");
  src: url("/font/poppins/Poppins-Bold.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-Bold.woff") format("woff"), url("/font/poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-ExtraBoldItalic';
  src: url("/font/poppins/Poppins-ExtraBoldItalic.eot");
  src: url("/font/poppins/Poppins-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-ExtraBoldItalic.woff") format("woff"), url("/font/poppins/Poppins-ExtraBoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-ExtraBold';
  src: url("/font/poppins/Poppins-ExtraBold.eot");
  src: url("/font/poppins/Poppins-ExtraBold.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-ExtraBold.woff") format("woff"), url("/font/poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-ExtraLightItalic';
  src: url("/font/poppins/Poppins-ExtraLightItalic.eot");
  src: url("/font/poppins/Poppins-ExtraLightItalic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-ExtraLightItalic.woff") format("woff"), url("/font/poppins/Poppins-ExtraLightItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-Medium';
  src: url("/font/poppins/Poppins-Medium.eot");
  src: url("/font/poppins/Poppins-Medium.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-Medium.woff") format("woff"), url("/font/poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-Black';
  src: url("/font/poppins/Poppins-Black.eot");
  src: url("/font/poppins/Poppins-Black.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-Black.woff") format("woff"), url("/font/poppins/Poppins-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-BlackItalic';
  src: url("/font/poppins/Poppins-BlackItalic.eot");
  src: url("/font/poppins/Poppins-BlackItalic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-BlackItalic.woff") format("woff"), url("/font/poppins/Poppins-BlackItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-BoldItalic';
  src: url("/font/poppins/Poppins-BoldItalic.eot");
  src: url("/font/poppins/Poppins-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-BoldItalic.woff") format("woff"), url("/font/poppins/Poppins-BoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-ThinItalic';
  src: url("/font/poppins/Poppins-ThinItalic.eot");
  src: url("/font/poppins/Poppins-ThinItalic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-ThinItalic.woff") format("woff"), url("/font/poppins/Poppins-ThinItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-Thin';
  src: url("/font/poppins/Poppins-Thin.eot");
  src: url("/font/poppins/Poppins-Thin.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-Thin.woff") format("woff"), url("/font/poppins/Poppins-Thin.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-MediumItalic';
  src: url("/font/poppins/Poppins-MediumItalic.eot");
  src: url("/font/poppins/Poppins-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-MediumItalic.woff") format("woff"), url("/font/poppins/Poppins-MediumItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-Regular';
  src: url("/font/poppins/Poppins-Regular.eot");
  src: url("/font/poppins/Poppins-Regular.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-Regular.woff") format("woff"), url("/font/poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-SemiBoldItalic';
  src: url("/font/poppins/Poppins-SemiBoldItalic.eot");
  src: url("/font/poppins/Poppins-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-SemiBoldItalic.woff") format("woff"), url("/font/poppins/Poppins-SemiBoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins-LightItalic';
  src: url("/font/poppins/Poppins-LightItalic.eot");
  src: url("/font/poppins/Poppins-LightItalic.eot?#iefix") format("embedded-opentype"), url("/font/poppins/Poppins-LightItalic.woff") format("woff"), url("/font/poppins/Poppins-LightItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

html, body {
  font-size: 1em;
  font-family: 'Roboto-Light';
  color: #3d495e;
  line-height: 1.5em; }

h1 {
  font-family: 'Poppins-Regular';
  color: #0b1742;
  font-size: 3em;
  font-weight: normal;
  line-height: 1.20em;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0.3333em;
  padding: 0; }
  h1 span {
    color: #004f9f; }
  h1 em {
    font-family: 'Poppins-Medium';
    color: #004f9f;
    font-style: normal; }
  h1 strong {
    font-family: 'Poppins-Medium';
    font-weight: normal; }
  h1.os-typography--white {
    color: #FFF; }
    h1.os-typography--white span {
      color: #DCE7F5; }
    h1.os-typography--white em {
      color: #DCE7F5; }
  @media (min-width: 1440px) and (max-width: 1919px) {
    h1 {
      font-size: 3em; } }
  @media (min-width: 1024px) and (max-width: 1439px) {
    h1 {
      font-size: 3em; } }
  @media (min-width: 840px) and (max-width: 1023px) {
    h1 {
      font-size: 2.6181em; } }
  @media (min-width: 720px) and (max-width: 839px) {
    h1 {
      font-size: 2.6181em; } }
  @media (min-width: 600px) and (max-width: 719px) {
    h1 {
      font-size: 2.4em; } }
  @media (min-width: 400px) and (max-width: 599px) {
    h1 {
      font-size: 1.964em; } }
  @media (min-width: 360px) and (max-width: 399px) {
    h1 {
      font-size: 1.745em; } }
  @media (max-width: 359px) {
    h1 {
      font-size: 1.745em; } }

h2 {
  font-family: 'Poppins-Light';
  color: #0b1742;
  font-size: 2.75em;
  line-height: 1.20em;
  text-transform: uppercase;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 0.3333em; }
  h2 span {
    color: #004f9f; }
  h2 em {
    font-family: 'Poppins-Medium';
    color: #004f9f;
    font-style: normal; }
  h2 strong {
    font-family: 'Poppins-Medium';
    font-weight: normal; }
  h2.os-typography--white {
    color: #FFF; }
    h2.os-typography--white span {
      color: #DCE7F5; }
    h2.os-typography--white em {
      color: #DCE7F5; }
  @media (min-width: 1440px) and (max-width: 1919px) {
    h2 {
      font-size: 2.75em; } }
  @media (min-width: 1024px) and (max-width: 1439px) {
    h2 {
      font-size: 2.75em; } }
  @media (min-width: 840px) and (max-width: 1023px) {
    h2 {
      font-size: 2.4em; } }
  @media (min-width: 720px) and (max-width: 839px) {
    h2 {
      font-size: 2.4em; } }
  @media (min-width: 600px) and (max-width: 719px) {
    h2 {
      font-size: 2.2em; } }
  @media (min-width: 400px) and (max-width: 599px) {
    h2 {
      font-size: 1.8em; } }
  @media (min-width: 360px) and (max-width: 399px) {
    h2 {
      font-size: 1.6em; } }
  @media (max-width: 359px) {
    h2 {
      font-size: 1.6em; } }

h3 {
  font-family: 'Poppins-Light';
  color: #0b1742;
  font-weight: normal;
  font-size: 2em;
  line-height: 1.20em;
  margin-top: 0;
  margin-bottom: 0.5em;
  padding: 0;
  line-height: 1.25em;
  text-transform: uppercase; }
  h3 span {
    color: #004f9f; }
  h3 em {
    font-family: 'Poppins-Medium';
    color: #004f9f;
    font-style: normal; }
  h3 strong {
    font-family: 'Poppins-Medium';
    font-weight: normal; }
  h3.os-typography--white {
    color: #FFF; }
    h3.os-typography--white span {
      color: #DCE7F5; }
    h3.os-typography--white em {
      color: #DCE7F5; }
  @media (min-width: 1440px) and (max-width: 1919px) {
    h3 {
      font-size: 2em; } }
  @media (min-width: 1024px) and (max-width: 1439px) {
    h3 {
      font-size: 2em; } }
  @media (min-width: 840px) and (max-width: 1023px) {
    h3 {
      font-size: 1.6655em; } }
  @media (min-width: 720px) and (max-width: 839px) {
    h3 {
      font-size: 1.6655em; } }
  @media (min-width: 600px) and (max-width: 719px) {
    h3 {
      font-size: 1.5em; } }
  @media (min-width: 400px) and (max-width: 599px) {
    h3 {
      font-size: 1.5em; } }
  @media (min-width: 360px) and (max-width: 399px) {
    h3 {
      font-size: 1.3em; } }
  @media (max-width: 359px) {
    h3 {
      font-size: 1.3em; } }

.os-typography__h1-leading-text {
  font-family: 'Poppins-Regular';
  font-weight: normal;
  font-size: 1.2em;
  color: #3d495e; }
  .os-typography__h1-leading-text.os-typography--white {
    color: #FFF; }

.os-typography__h2-leading-text {
  font-family: 'Poppins-Light';
  font-size: 1.1em;
  line-height: 1.65em;
  color: #3d495e;
  font-weight: normal; }
  @media (max-width: 599px) {
    .os-typography__h2-leading-text {
      font-size: 1em; } }
  .os-typography__h2-leading-text.os-typography--white {
    color: #FFF; }

.article h3 {
  font-family: 'Poppins-Regular';
  text-transform: none;
  color: #004f9f;
  font-size: 1.4em; }

.article h4 {
  color: #3d495e;
  font-family: 'Roboto-Bold';
  margin-top: 0.25em;
  margin-bottom: 0.25em; }

.os-typography__form-alert {
  color: orange;
  font-size: 0.9em; }

.home-right-choice .home-right-choice__element > .mdc-layout-grid {
  padding: 1.5em;
  max-width: calc(100% - (171.4296% - 1280px) / 2);
  margin: 0 0 0 auto; }

@media (max-width: 839px) {
  .home-right-choice .home-right-choice__section {
    padding: 2em 0 2em 0;
    margin-bottom: 3em; } }

.home-right-choice .home-right-choice__section .mdc-grid-layout {
  overflow: hidden;
  padding-bottom: -1em; }

.home-right-choice .home-right-choice__container {
  position: relative;
  z-index: 2;
  padding: 3em 0 3em 0;
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1107.0012 510' style='enable-background:new 0 0 1107.0012 510;' xml:space='preserve' preserveAspectRatio='none'%3E %3Cstyle type='text/css'%3E .st0%7Bfill:url(%23SVGID_1_);%7D %3C/style%3E %3ClinearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='600.5872' y1='273.4937' x2='-32.1842' y2='70.0231' gradientTransform='matrix(1 0 0 -1 0 512)'%3E %3Cstop offset='0' style='stop-color:%23102D69'/%3E %3Cstop offset='1' style='stop-color:%23004F9F'/%3E %3C/linearGradient%3E %3Cpolygon class='st0' points='1190,-20 -11,-20 -11,521 1137.5762,521 1190,328.4899 '/%3E %3C/svg%3E");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;
  align-items: right; }

.home-right-choice .home-right-choice__mobile-bowtie {
  position: absolute;
  z-index: 6;
  left: 0;
  width: 100%;
  height: 6.5em;
  bottom: -2.3em;
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 600 127.6598' enable-background='new 0 0 600 127.6598' xml:space='preserve'%3E %3Cpolygon opacity='0.65' fill='%2301519C' enable-background='new ' points='600,82.0944 600,0 0,127.6598 0,82.0944 '/%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover; }

.home-right-choice .home-right-choice__desktop-bowtie {
  position: absolute;
  width: 7em;
  height: 100%;
  top: 0;
  right: -4.5em; }

.home-right-choice .home-right-choice__desktop-bowtie svg {
  height: 100%;
  width: 100%;
  object-fit: contain; }

.home-right-choice .home-right-choice__background-image {
  position: relative;
  height: 100%;
  background-image: url(/image/home-right-choice/couple-laptop.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top; }

@media (min-width: 400px) and (max-width: 479px) {
  .home-right-choice {
    margin-top: 1em; } }

@media (min-width: 360px) and (max-width: 399px) {
  .home-right-choice {
    margin-top: 1em; } }

@media (max-width: 359px) {
  .home-right-choice {
    margin-top: 1em; } }

.home-right-choice h1, .home-right-choice p {
  color: #FFF; }
  .home-right-choice h1 span, .home-right-choice p span {
    color: #FFF; }
  .home-right-choice h1 em, .home-right-choice p em {
    color: #FFF; }
