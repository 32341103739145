.home-ifop-partner {
  box-sizing: border-box; }
  @media (min-width: 840px) {
    .home-ifop-partner {
      background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1920 392.9199' enable-background='new 0 0 1920 392.9199' xml:space='preserve'%3E %3Cpolygon fill='%2323AE84' points='1726.1272,107.7226 1920,88.4218 1920,38.6562 1730.767,57.4941 '/%3E %3Cpolygon fill='%23ECF3FB' points='1636.9246,17.2373 1632.2847,67.4653 1805.437,50.2285 1810.077,0 '/%3E %3Cpolygon fill='%23DCE7F5' points='129.0142,278.744 124.3743,328.972 297.5267,311.7352 302.1667,261.5067 '/%3E %3Cpolygon opacity='0.5' fill='%23ECF3FB' points='480,94.2842 235.3213,118.6426 230.6814,168.8701 475.36,144.5122 '/%3E %3Cpolygon fill='%23DCE7F5' points='1600.5548,122.1147 1332.6371,148.7866 1327.9972,199.0142 1595.9149,172.3423 '/%3E %3Cpolygon fill='%2323AE84' points='0,242.329 0,292.0946 198.0271,272.3808 202.667,222.1533 '/%3E %3Cpolygon fill='%23ECF3FB' points='1515.1626,230.091 1664.1367,215.26 1668.7766,165.0324 1519.8025,179.8635 '/%3E %3Cpolygon opacity='0.5' fill='%23ECF3FB' points='0,343.1542 0,392.9199 59.825,386.9647 64.4649,336.7372 '/%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3Cg%3E %3C/g%3E %3C/svg%3E");
      background-repeat: no-repeat;
      background-position: top right;
      background-size: contain; } }
  .home-ifop-partner .home-ifop-partner__content-wrapper > .mdc-layout-grid {
    max-width: 1280px; }
  @media (max-width: 839px) {
    .home-ifop-partner .home-ifop-partner__gradient-top {
      background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1001.6602 138' style='enable-background:new 0 0 1001.6602 138;' xml:space='preserve'%3E %3Cstyle type='text/css'%3E .st0%7Bfill:url(%23SVGID_1_);%7D %3C/style%3E %3ClinearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='29.0953' y1='69' x2='965.9149' y2='69'%3E %3Cstop offset='0' style='stop-color:%23ECF3FB'/%3E %3Cstop offset='1' style='stop-color:%23ECF3FB;stop-opacity:0'/%3E %3C/linearGradient%3E %3Cpolygon class='st0' points='0,138 1001.6602,138 1001.6602,101.4629 0,0 '/%3E %3C/svg%3E");
      background-repeat: no-repeat;
      width: 100%;
      background-position: bottom left;
      background-size: 100% auto; }
    .home-ifop-partner .home-ifop-partner__content-wrapper {
      background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1001.6602 422.1411' style='enable-background:new 0 0 1001.6602 422.1411;' xml:space='preserve' preserveAspectRatio='none'%3E %3Cstyle type='text/css'%3E .st0%7Bfill:url(%23SVGID_1_);%7D %3C/style%3E %3Cg%3E %3ClinearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='29.0953' y1='211.0706' x2='965.9149' y2='211.0706'%3E %3Cstop offset='0' style='stop-color:%23ECF3FB'/%3E %3Cstop offset='1' style='stop-color:%23ECF3FB;stop-opacity:0'/%3E %3C/linearGradient%3E %3Crect class='st0' width='1001.6602' height='422.1411'/%3E %3C/g%3E %3C/svg%3E");
      background-repeat: no-repeat;
      width: 100%;
      background-position: center left;
      background-size: 100% 100%; }
    .home-ifop-partner .home-ifop-partner__gradient-bottom {
      background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1001.6602 108.6616' style='enable-background:new 0 0 1001.6602 108.6616;' xml:space='preserve'%3E %3Cstyle type='text/css'%3E .st0%7Bfill:url(%23SVGID_1_);%7D %3C/style%3E %3ClinearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='29.0953' y1='54.3308' x2='965.9149' y2='54.3308'%3E %3Cstop offset='0' style='stop-color:%23ECF3FB'/%3E %3Cstop offset='1' style='stop-color:%23ECF3FB;stop-opacity:0'/%3E %3C/linearGradient%3E %3Cpolygon class='st0' points='0,7.1987 1001.6602,108.6616 1001.6602,0 0,0 '/%3E %3C/svg%3E");
      background-repeat: no-repeat;
      width: 100%;
      background-position: top right;
      background-size: 100% auto; } }
  @media (min-width: 720px) and (max-width: 839px) {
    .home-ifop-partner .home-ifop-partner__gradient-top {
      height: 7.188em; }
    .home-ifop-partner .home-ifop-partner__gradient-bottom {
      height: 6.688em; } }
  @media (min-width: 600px) and (max-width: 719px) {
    .home-ifop-partner .home-ifop-partner__gradient-top {
      height: 6.088em; }
    .home-ifop-partner .home-ifop-partner__gradient-bottom {
      height: 3.488em; } }
  @media (min-width: 480px) and (max-width: 599px) {
    .home-ifop-partner .home-ifop-partner__gradient-top {
      height: 5.088em; }
    .home-ifop-partner .home-ifop-partner__gradient-bottom {
      height: 3.688em; } }
  @media (min-width: 400px) and (max-width: 479px) {
    .home-ifop-partner .home-ifop-partner__gradient-top {
      height: 4.088em; }
    .home-ifop-partner .home-ifop-partner__gradient-bottom {
      height: 3.388em; } }
  @media (min-width: 360px) and (max-width: 399px) {
    .home-ifop-partner .home-ifop-partner__gradient-top {
      height: 3.388em; }
    .home-ifop-partner .home-ifop-partner__gradient-bottom {
      height: 2.688em; } }
  .home-ifop-partner .os-ifop-svg {
    display: block;
    margin: auto;
    width: 385px;
    height: 340px; }
    @media (max-width: 599px) {
      .home-ifop-partner .os-ifop-svg {
        width: 265px;
        height: 257px; } }
    .home-ifop-partner .os-ifop-svg svg {
      width: 100%; }
  @media (min-width: 1920px) {
    .home-ifop-partner {
      padding-top: 4em; } }
  @media (min-width: 1440px) and (max-width: 1919px) {
    .home-ifop-partner {
      padding-top: 4em; } }
  @media (min-width: 1024px) and (max-width: 1439px) {
    .home-ifop-partner {
      padding-top: 4em; } }
  @media (min-width: 840px) and (max-width: 1023px) {
    .home-ifop-partner {
      padding-top: 4em; } }
  @media (min-width: 720px) and (max-width: 839px) {
    .home-ifop-partner {
      padding-top: 4em; } }
  @media (min-width: 600px) and (max-width: 719px) {
    .home-ifop-partner {
      padding-top: 4em; } }
  @media (min-width: 480px) and (max-width: 599px) {
    .home-ifop-partner {
      text-align: center; }
      .home-ifop-partner hr {
        margin: 0 auto; } }
  @media (min-width: 360px) and (max-width: 399px) {
    .home-ifop-partner {
      padding-top: 2em;
      text-align: center; }
      .home-ifop-partner hr {
        margin: 0 auto; } }
  @media (max-width: 359px) {
    .home-ifop-partner {
      padding-top: 2em;
      text-align: center; }
      .home-ifop-partner hr {
        margin: 0 auto; } }
