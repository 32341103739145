.home-transparency-guarantee {
  box-sizing: border-box;
  position: relative;
  background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1001.6602 446.7799' enable-background='new 0 0 1001.6602 446.7799' xml:space='preserve' preserveAspectRatio='none'%3E %3ClinearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='972.5648' y1='223.39' x2='35.7452' y2='223.39'%3E %3Cstop offset='0' style='stop-color:%23ECF3FB'/%3E %3Cstop offset='1' style='stop-color:%23ECF3FB;stop-opacity:0'/%3E %3C/linearGradient%3E %3Cpolygon fill='url(%23SVGID_1_)' points='1001.6602,0 0,0 0,446.7799 1001.6602,345.317 '/%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 49%;
  margin-top: 1px;
  margin-bottom: 3em; }
  .home-transparency-guarantee > .mdc-layout-grid {
    max-width: 1280px; }
  .home-transparency-guarantee .home-transparency-guarantee__header-cell {
    text-align: center; }
  @media (max-width: 1023px) {
    .home-transparency-guarantee {
      margin-bottom: 0;
      background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='utf-8'?%3E %3C!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 1001.6602 668.8026' enable-background='new 0 0 1001.6602 668.8026' xml:space='preserve'%3E %3ClinearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='-3289.9626' y1='335.5987' x2='-4226.7822' y2='335.5987' gradientTransform='matrix(1 0 0 -1 4262.5273 670)'%3E %3Cstop offset='0' style='stop-color:%23ECF3FB'/%3E %3Cstop offset='1' style='stop-color:%23ECF3FB;stop-opacity:0'/%3E %3C/linearGradient%3E %3Cpolygon fill='url(%23SVGID_1_)' points='0,668.8026 1001.6602,567.3398 1001.6602,0 0,101.4628 '/%3E %3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right top;
      margin-top: 0;
      padding-top: 3em;
      background-size: cover; }
      .home-transparency-guarantee hr {
        display: none; } }
  @media (min-width: 600px) and (max-width: 719px) {
    .home-transparency-guarantee {
      padding-top: 5em;
      margin-bottom: 2em; } }
  @media (min-width: 720px) and (max-width: 839px) {
    .home-transparency-guarantee {
      padding-top: 5em;
      margin-bottom: 2em; } }
  @media (min-width: 840px) and (max-width: 1023px) {
    .home-transparency-guarantee {
      padding-top: 5em; } }
  @media (max-width: 599px) {
    .home-transparency-guarantee .button-center-wrapper {
      width: 100%;
      text-align: center; } }

.svg-container {
  position: relative;
  height: 100%; }
  .svg-container .os-tablet-svg {
    display: block;
    margin: auto;
    width: 450px;
    height: 260px;
    margin-top: 7em; }
    @media (max-width: 599px) {
      .svg-container .os-tablet-svg {
        margin-top: 0;
        padding: 1em 0;
        width: 265px;
        height: 203px; } }
    @media (max-width: 768px) {
      .svg-container .os-tablet-svg {
        margin-top: 0;
        padding: 1em 0;
        width: 265px;
        height: 203px; } }
    .svg-container .os-tablet-svg svg {
      width: 100%; }
  .svg-container .os-badge-svg {
    display: block;
    position: absolute;
    margin: auto;
    width: 210px;
    height: 210px;
    top: -7em;
    left: 13em; }
    @media (max-width: 599px) {
      .svg-container .os-badge-svg {
        margin-top: 0;
        padding: 1em 0;
        width: 125px;
        height: 125px;
        top: 0em;
        left: 9em; } }
    @media (max-width: 768px) {
      .svg-container .os-badge-svg {
        margin-top: 0;
        padding: 1em 0;
        width: 150px;
        height: 150px;
        top: -1em;
        left: 8em; } }
    .svg-container .os-badge-svg svg {
      width: 100%; }
  .svg-container .os-triangles-svg-2 {
    position: absolute;
    bottom: -7.7em;
    left: -5em;
    width: 156px;
    height: 156px;
    z-index: 5; }
    @media (min-width: 360px) and (max-width: 399px) {
      .svg-container .os-triangles-svg-2 {
        bottom: -17.9em;
        left: 0;
        width: 75px;
        height: 76px; } }
    @media (min-width: 480px) and (max-width: 1024px) {
      .svg-container .os-triangles-svg-2 {
        /* left: 0;
			height: 100px;
			width: 100px;
			bottom: -5.4em; */
        display: none; } }
    .svg-container .os-triangles-svg-2 svg {
      width: 100%; }
