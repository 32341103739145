.home-header {
  position: relative;
  width: 100%;
  height: 54em;
  background-image: url(/image/home-header/home-header-desktop.jpg);
  background-position: center top;
  background-repeat: no-repeat;
  display: inline-block; }
  @media (min-width: 840px) and (max-width: 1023px) {
    .home-header {
      height: 32em;
      background-image: url(/image/home-header/home-header-medium.jpg); } }
  @media (min-width: 600px) and (max-width: 839px) {
    .home-header {
      height: 28em;
      background-image: url(/image/home-header/home-header-medium.jpg); } }
  @media (max-width: 599px) {
    .home-header {
      height: 18em;
      background-color: #FFF;
      background-image: url(/image/home-header/home-header-mobile.jpg); } }
  .home-header > .mdc-layout-grid {
    max-width: 1280px; }
    @media (min-width: 1920px) {
      .home-header > .mdc-layout-grid {
        margin-top: 17em; } }
    @media (min-width: 1440px) and (max-width: 1919px) {
      .home-header > .mdc-layout-grid {
        margin-top: 17em; } }
    @media (min-width: 1024px) and (max-width: 1439px) {
      .home-header > .mdc-layout-grid {
        margin-top: 17em; } }
    @media (min-width: 840px) and (max-width: 1023px) {
      .home-header > .mdc-layout-grid {
        display: none; } }
    @media (min-width: 720px) and (max-width: 839px) {
      .home-header > .mdc-layout-grid {
        margin-top: 10em; } }
    @media (min-width: 600px) and (max-width: 719px) {
      .home-header > .mdc-layout-grid {
        margin-top: 10em; } }

.home-header__bg-overlay {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: -0.5em; }
  .home-header__bg-overlay svg {
    width: 100%; }

@media (min-width: 840px) and (max-width: 1023px) {
  .mdc-layout-grid--hide-desktop.home-header-mobile {
    display: block !important; }
  .header-bg-overlay--desk {
    display: none; } }

@media (min-width: 1024px) {
  .header-bg-overlay--mob {
    display: none; } }

.home-header-mobile .mdc-layout-grid {
  text-align: center; }

.home-header__os-triangles-svg {
  position: absolute;
  z-index: 2; }
  @media (max-width: 599px) {
    .home-header__os-triangles-svg {
      bottom: 0%;
      right: 7%;
      width: 106px;
      height: 84px; } }
  @media (min-width: 600px) and (max-width: 839px) {
    .home-header__os-triangles-svg {
      width: 155px;
      height: 126px;
      right: 10%;
      bottom: 3%; } }
  @media (min-width: 840px) and (max-width: 1023px) {
    .home-header__os-triangles-svg {
      bottom: 7%;
      width: 175px;
      height: 138px;
      right: 10%; } }
  @media (min-width: 1024px) and (max-width: 1439px) {
    .home-header__os-triangles-svg {
      width: 245px;
      height: 193px;
      right: 10%;
      bottom: 3%; } }
  @media (min-width: 1440px) {
    .home-header__os-triangles-svg {
      bottom: 4%;
      right: 7%;
      width: 325px;
      height: 260px; } }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .home-header__os-triangles-svg {
      bottom: 4.5%; } }
  .home-header__os-triangles-svg svg {
    width: 100%; }
